import {
  Grid,
  Paper,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useContext } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import { CurrenciesContext } from "../../context/currencies";
import { LanguagesContext } from "../../context/languages";
import { ReportEncodingsContext } from "../../context/report-encodings";
import { TimezonesContext } from "../../context/timezones";
import Colors from "../../enums/colors";
import { TranslationsContext } from "../../context/translations";

const PreferencesSettings = ({
  account,
  handleEdit,
  formIsValid,
  handleSaveProfile,
}) => {
  const { languages } = useContext(LanguagesContext);
  const { timezones } = useContext(TimezonesContext);
  const { currencies } = useContext(CurrenciesContext);
  const { reportEncodings } = useContext(ReportEncodingsContext);
  const { translations } = useContext(TranslationsContext);

  const UTFTexts = translations["help.report_encoding"]?.split("<br>");
  const UTF8Text = UTFTexts
    ? UTFTexts[0]
    : "* UTF-8 is the standard format for downloading reports. It uses the least number of bytes, meaning it will download your reports faster than UTF-16LE.";
  const UTF16Text = UTFTexts
    ? UTFTexts[1]
    : "* UTF-16LE uses more bytes but should be used for any non Latin characters such as those from the Chinese or Japanese language.";

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3}>
        <Box paddingX={2} paddingBottom={2}>
          <Grid container>
            <Grid item xs={12} md={12} paddingBottom={3}>
              <h1>{translations["heading.preferences"] ?? "Preferences"}</h1>
              <Divider />
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={account.user_profile.signed_up_to_mailing_list}
                        onChange={handleEdit}
                        name="mailing-list"
                        color="primary"
                      />
                    }
                    label={
                      translations["label.signed_up_to_mailing_list"] ??
                      "Signed Up To Mailing List"
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {translations["label.timezone"] ?? "Timezone"}
                  </InputLabel>
                  <Select
                    name="timezone"
                    value={account.user_profile.timezone.id}
                    onChange={handleEdit}
                    label={translations["label.timezone"] ?? "Timezone"}
                  >
                    {timezones.map((timezone) => (
                      <MenuItem value={timezone?.id} key={timezone?.id}>
                        {timezone?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {translations["label.language"] ?? "Language"}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="language"
                    value={account.user_profile.language.id}
                    onChange={handleEdit}
                    label={translations["label.language"] ?? "Language"}
                  >
                    {languages?.map((language) => (
                      <MenuItem value={language?.id} key={language?.id}>
                        {language?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel>
                    {translations["label.product_price_currency"] ??
                      "Product Price Currency"}
                  </InputLabel>
                  <Select
                    name="currency"
                    value={account.product_currency.id}
                    onChange={handleEdit}
                    label={
                      translations["label.product_price_currency"] ??
                      "Product Price Currency"
                    }
                  >
                    {currencies?.map((currency) => (
                      <MenuItem value={currency?.id} key={currency?.id}>
                        {currency?.name} ({currency?.iso_code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {translations["label.report_export_encoding"] ??
                        "Report Export Encoding"}
                    </InputLabel>
                    <Select
                      name="encoding"
                      value={account.report_encoding}
                      onChange={handleEdit}
                      label={
                        translations["label.report_export_encoding"] ??
                        "Report Export Encoding"
                      }
                    >
                      {reportEncodings?.map((reportEncoding) => (
                        <MenuItem
                          value={reportEncoding?.id}
                          key={reportEncoding?.id}
                        >
                          {reportEncoding?.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography fontSize="12px" color={Colors.grey}>
                    {UTF8Text}
                  </Typography>
                  <Typography fontSize="12px" color={Colors.grey}>
                    {UTF16Text}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack direction="row" paddingY={2}>
                    <ActionButtonSmall
                      disabled={!formIsValid}
                      name="save"
                      sx={{ backgroundColor: "#667080" }}
                      onClick={() =>
                        handleSaveProfile(
                          translations["flash_message.preferences_updated"] ??
                            "Preferences updated successfully."
                        )
                      }
                    >
                      {translations["button.save"] ?? "Save"}
                    </ActionButtonSmall>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default PreferencesSettings;
